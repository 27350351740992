.Line {
  position: relative !important;
  border-left: 2px solid var(--blue);
  width: 2px;
}

.WideLine {
  border-left: 5px solid var(--orange);
  width: 5px;
  position: absolute;
  left: calc(50% - 1px);
  transform: translateX(-50%);
}

.DarkLine {
  border-color: var(--blue);
}
