:root {
  --blue: #000046;
  --blue-light: #2a286c;
  --blue-dark: #000046;
  --grey: #f5f5f4;
  --grey-dark: #e0e0e0;
  --orange: #f34202;
  --orange-dark: #d42e00;
  --green: #013e35;
  --green-dark: #00352d;
  --yellow: #ff9100;
  --beige: #ebc7a3;
  --white: #f9f9fb;
  --grey-alt-dark: #4b4a4a;
  --grey-alt-light: #7d7c7c;
  --nav-height: 70px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Catamaran", sans-serif;
}

a {
  white-space: nowrap;
}

html,
body {
  scroll-behavior: auto !important;
}

html {
  font-size: 100%;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

/* Breakpoint Container */
@media (min-width: 1400px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1750px) {
  .container {
    max-width: 1500px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

body {
  position: relative;
}

html,
body {
  min-width: 100%;
  overflow-x: hidden;
}

button,
aside {
  -webkit-tap-highlight-color: transparent;
}

.phrases-container {
  position: relative;
  width: fit-content;
}

.main-phrase {
  height: 133px;
}

.phrase-loop-container {
  position: absolute;
  /* top: -65px !important; */
  top: -32.5px;
  left: 100%;
  /* background-color: yellow; */
  height: 133px;
  width: 800px;
  overflow-y: hidden;
  overflow-x: visible;
}

.phrase-loop-container h3 {
  position: absolute;
  color: var(--orange);
  /* white-space: nowrap; */
  opacity: 0;
}

@media only screen and (max-width: 900px) {
  .phrase-loop-container p {
    font-size: 36px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  padding: 20px;
}

.no-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-highlight:focus {
  outline: none !important;
}

h1,
h2 {
  font-size: 2.5rem;
  color: var(--blue-dark);
  font-weight: 600;
  line-height: 2.8rem;
  margin-bottom: 2rem;
}

.h2-2 {
  font-size: 2.2rem;
  line-height: 40px;
}

h3 {
  font-size: 1.75rem;
  color: var(--blue-dark);
  font-weight: 600;
}

h4 {
  font-size: 1.125rem;
  color: var(--blue-dark);
  font-weight: 600;
}

p {
  font-size: 1rem;
  color: var(--blue-dark);
  font-weight: 500;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}

.button-container {
  margin-left: 1.5rem;
}

.grey-bg {
  background-color: var(--grey);
  z-index: -1;
}

.border-bo {
  border-bottom: 2px solid var(--orange);
}

.border-ro {
  border-right: 2px solid var(--orange);
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pl-1-5 {
  padding-left: 1.5rem;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-m90 {
  transform: rotate(-90deg);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.form-control {
  position: relative;
}

.form-control:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  text-decoration: none;
  width: 110px !important;
}

.social-icon img {
  width: 40px !important;
}

.social-icon p {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.ms-6 {
  margin-left: 6rem;
}

.input-left {
  text-align: left !important;
  padding-left: 1rem !important;
}

.color-orange {
  color: var(--orange);
}

.small-text {
  font-size: 0.75rem;
}

.bullet {
  opacity: 1;
}

/* ECOSYSTEM */

#Ecosystem_Main > * {
  opacity: 1;
}

#Ecosystem a {
  text-decoration: none;
}

/* tyc */
.button-link,
.dropdown-toggle {
  background: transparent !important;
  border: none !important;
  color: white !important;
  font-weight: 600;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  box-shadow: 0px 2px 4px 0px #00000033;
}

.dropdown-menu a {
  color: #000046 !important;
}

.dropdown-item:hover {
  color: #f34202 !important;
  background-color: transparent !important;
  font-weight: 600;
}

@media (min-width: 768px) {
  .container-right {
    margin-right: 0;
    margin-left: calc(50vw - 375px);
  }
}

@media (min-width: 992px) {
  .container-right {
    margin-right: 0;
    margin-left: calc(50vw - 485px);
  }
}

@media (min-width: 1200px) {
  .container-right {
    margin-right: 0;
    margin-left: calc(50vw - 585px);
  }
}

@media (min-width: 1600px) {
  .container-right {
    margin-right: 0;
    margin-left: calc(50vw - 800px);
  }
}

@media (max-width: 768px) {
  .navbar {
    height: auto !important;
  }

  .navbar-collapse {
    padding-top: 1rem;
  }

  .border-ro {
    border: none;
  }

  .phrases-container {
    width: 100%;
  }

  .main-phrase {
    width: 100%;
  }

  .phrase-loop-container {
    position: absolute;
    top: 0;
    left: 0.8rem;
    height: calc(32.5px * 2);
    width: 100%;
    overflow-y: initial;
    overflow-x: initial !important;
    word-wrap: break-word;
  }
}

@media (min-width: 1500px) {
  html {
    font-size: 110%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 140%;
  }
}
