.Button {
  padding: 0.1rem 0.8rem;
  font-size: 0.85rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  /* width: 155px; */
  transition: all 200ms;
  text-decoration: none;
  box-sizing: border-box;
}

.Primary {
  background-color: var(--orange);
  border: 2px solid var(--orange);
  color: white;
}

.Secondary {
  border: 2px solid var(--orange);
  color: var(--orange);
}

.Tertiary {
  background-color: var(--green);
  border: 2px solid var(--green);
  color: white;
}

.Quaternary {
  background-color: var(--blue);
  border: 2px solid var(--blue);
  color: white;
}

.Quinary {
  border: 2px solid var(--blue);
  color: var(--blue);
}

.InvertedPrimary {
  background-color: white;
  color: var(--orange) !important;
}

.InvertedTertiary {
  background-color: white;
  color: var(--green) !important;
}

.Primary:hover {
  border: 2px solid var(--orange-dark);
  background-color: var(--orange-dark);
  border-color: var(--orange-dark);
  color: white;
}

.Secondary:hover {
  border-color: var(--orange-dark);
  color: var(--orange-dark);
}

.Tertiary:hover {
  color: white;
  background-color: var(--green-dark);
  border-color: var(--green-dark);
}

.Quaternary:hover {
  color: white;
  background-color: var(--blue-light);
  border-color: var(--blue-light);
}

.Quinary:hover {
  border-color: var(--blue-light);
  color: var(--blue-light);
}
