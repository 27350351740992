.DarumaReturnsImage {
  position: relative;
  height: 100%;
  width: 100%;
}

.DarumaReturnsImage img:nth-child(1) {
  width: 90%;
  position: absolute;
  left: 80px;
  top: -40px;
}

.DarumaReturnsImage img:nth-child(2) {
  width: 25%;
  position: absolute;
  top: 35px;
  left: 0;
}

.ClientsCarousel {
  display: flex;
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  /* Microsoft Edge and Firefox 35+ */
}

.ClientsCarousel :global .use-spring-carousel-main-wrapper {
  overflow-x: hidden !important;
}

.ClientsCarousel :global .use-spring-carousel-item img {
  width: 80%;
  max-height: 60px;
  object-fit: contain;
}

.Chevron {
  width: 45px;
  cursor: pointer;
}

.ClientHand {
  width: 45px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.AgroBanner {
  position: relative;
  background-image: url("./assets/images/agro-banner.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 0;
  padding-top: 32% !important;
}

.AgroBanner * {
  color: white;
}

.ConstructionBanner {
  position: relative;
  background-image: url("./assets/images/young-man-engineer-working-on-factory.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 0;
  padding-top: 32% !important;
  color: white !important;
}

.ConstructionBanner * {
  color: white;
}

.ConstructionBanner img {
  width: 180px;
}

.Container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.BlueDarumaBg {
  position: relative;
  background-image: url("./assets/images/blue-daruma-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 30.5% !important;
  /* (img-height / img-width * container-width) */
  /* (613 / 1920 * 100) */
}

.IconBanner {
  padding: 6rem 0;
  background-color: var(--grey);
}

.BannerImage {
  width: 22rem;
}

.PhrasesContainer {
  display: flex;
}

/* 
.IntelligentPhrase {
  margin-top: 38px;
  margin-right: 0.8rem;
} */

.Phrases {
  color: var(--orange);
}

.Hero {
  padding-top: calc(var(--nav-height) + 6rem) !important;
  /* height: 100vh; */
  position: relative;
}

.HeroFullPage {
  padding-top: calc(var(--nav-height)) !important;
  height: 100vh;
  position: relative;
}

.HeroFullPageBig {
  padding-top: calc(var(--nav-height) + 4rem) !important;
  height: 100vh;
  position: relative;
}

.DarumaGreyBg {
  position: absolute;
  z-index: -1;
  height: 250%;
  left: -450px;
  opacity: 0.6;
}

.DarumaGreyBgPago {
  position: absolute;
  z-index: -1;
  height: 180%;
  left: -500px;
  opacity: 0.6;
}

.DarumaBlueBg {
  position: absolute;
  z-index: -1;
  right: -25%;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
}

.BigDaruma {
  position: absolute;
  z-index: -1;
  right: -115px;
  /* top: 20%; */
  width: 230px;
}

.BigDaruma20 {
  position: absolute;
  z-index: -1;
  right: -115px;
  top: 20%;
  width: 230px;
}

.BigDarumaMiddle {
  position: absolute;
  z-index: -1;
  right: -115px;
  width: 230px;
  top: 50%;
  transform: translateY(-50%);
}

.DarumaLaptop {
  position: absolute;
  left: -50px;
  top: -20px;
  width: 120%;
}

.AllariaLink {
  width: 160px;
}

.NosotrosImagen {
  width: 75%;
}

.CeoYoutube {
  -moz-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
  transition: all 400ms;
}

.CeoYoutube:hover {
  -moz-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
}

.Bullet h4 {
  font-weight: 400;
}

.Bullet img {
  width: 20px;
  height: 20px;
  margin-right: 0.35rem;
}

.Image {
  background-image: url("./assets/images/woman-talking-on-phone-and-writing.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}

.ImageAgro {
  background-image: url("./assets/images/agro-2-banner.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}

.IconContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.IconContainer a {
  width: 40px;
  padding: 0;
}

.IconContainer img {
  width: 100%;
}

.ManLaptop {
  width: 100%;
}

.BenefitList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 4rem;
}

.BenefitList div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.DarumaCircle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--orange);
  border-radius: 50%;
  width: 320px;
  height: 320px;
}

.DarumaCircle img {
  width: 250px;
}

.DarumaCircle p {
  color: white;
  font-size: 0.65rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.BlueBanner {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--blue-dark);
  color: white;
}

.BlueBanner p {
  color: white;
}

.BlueBanner h2 {
  color: white;
}

.OrangeBanner {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--orange);
  color: white;
}

.OrangeBanner p {
  color: white;
}

.OrangeBanner h2 {
  color: white;
}

.HomeLogo {
  width: 80%;
  margin-bottom: 2rem;
}

.DarumaCuentaHalfImage {
  position: absolute;
  width: 46rem;
  left: -10%;
  top: 50%;
  transform: translateY(-50%);
}

.DarumaCuentaLogo {
  margin: auto;
  width: 70%;
  display: block;
  margin-left: 20%;
  margin-bottom: 3rem;
}

.DarumaPagoLogo {
  margin: auto;
  width: 70%;
  display: block;
  margin-bottom: 3rem;
}

.DarumaPagoHalfImage {
  position: absolute;
  width: 22rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {

  .HeroFullPageBig {
    height: auto;
  }

  .BenefitList {
    grid-template-columns: 1fr;
  }

  .Hero {
    height: auto;
    padding-bottom: 3rem;
    padding-top: calc(var(--nav-height) + 3rem) !important;
  }

  .NosotrosImagen {
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 2rem;
  }

  .DarumaGreyBg {
    position: absolute;
    z-index: -1;
    height: 150%;
    left: -100px;
  }

  .DarumaGreyBgPago {
    position: absolute;
    z-index: -1;
    height: 120%;
    left: -200px;
  }

  .bulletsContainer ul {
    padding: 0 0.5rem;
    width: 100%;
  }

  .Image {
    height: 350px;
    object-fit: cover;
    background-attachment: initial;
    background-repeat: no-repeat;
  }

  .ImageAgro {
    height: 350px;
    object-fit: cover;
    background-attachment: initial;
    background-repeat: no-repeat;
  }

  .BlueDarumaBg {
    padding-top: 0 !important;
    height: inherit;
    background-color: var(--grey);
    background-image: none;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .Container {
    position: relative;
  }

  .DarumaReturnsImage {
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 250px;
  }

  .DarumaReturnsImage img:nth-child(1) {
    height: 100%;
    object-fit: contain;
    width: inherit;
    position: relative;
    left: 0;
    bottom: 0;
    top: inherit;
  }

  .DarumaReturnsImage img:nth-child(2) {
    width: 25%;
    position: absolute;
    top: inherit;
    bottom: 0;
    left: 5px;
  }

  .DarumaLaptop {
    position: relative;
    width: 100%;
    margin: auto;
    top: inherit;
    left: inherit;
    margin-top: 1rem;
  }

  .ConstructionBanner,
  .AgroBanner {
    background-attachment: initial;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 600px;
    padding-top: 0 !important;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ManLaptop {
    height: 350px;
    object-fit: cover;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {

  .ConstructionBanner,
  .AgroBanner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 500px;
    padding-top: 0 !important;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1600px) {
  .Hero {
    padding-top: calc(var(--nav-height) + 6rem) !important;
  }
}