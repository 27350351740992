.NotFound {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.NotFound:before {
  content: "";
  background-image: url("../../assets/svg/grey-daruma.svg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 30% -5%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  z-index: -1;
}

.NotFound img {
  width: 220px;
  margin-bottom: 4rem;
}

.NotFound a {
  font-size: 1.2rem;
  margin-top: 1rem;
}
