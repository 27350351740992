.CustomHero {
  padding-top: 0 !important;
}

.CustomHero:before {
  content: "";
  background-image: url("../../assets/svg/grey-daruma.svg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 30% -5%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  z-index: -1;
}

.CustomHero > * {
  z-index: 1;
}

.LogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  cursor: pointer;
}

.LogoContainer img {
  width: 18rem;
}

.AccountsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.BigDaruma {
  position: absolute;
  z-index: -1;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
}

.DarumaGreyBg {
  position: absolute;
  z-index: -1;
  height: 220%;
  opacity: 0.6;
  top: 50px;
  left: -450px;
}

.AccountCol {
  flex: 0 0 auto;
  width: 35%;
}

.AccountCol h2 {
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: var(--grey-alt-dark);
  margin-bottom: 1.1rem;
}

.AccountCol p {
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--grey-alt-light);
  margin-bottom: 0;
}

.AccountCol img {
  margin-bottom: 2rem;
  width: 300px;
}

.Content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.CustomContainer {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.ButtonContainer {
  margin-top: 1.2rem;
}

.CustomRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.Intro {
  height: 100vh;
  width: 100%;
  background-color: white;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.Intro:before {
  content: "";
  background-image: url("../../assets/svg/grey-daruma.svg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: 30% -5%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  z-index: -1;
}

.Intro > div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.Intro img {
  width: 450px;
  opacity: 1;
}

.Intro h2 {
  font-size: 1.4rem;
  opacity: 1;
}

.Intro h1 {
  font-size: 1.4rem;
  margin-top: 2rem;
  opacity: 1;
}

.SupportSection {
  text-align: center;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.SupportSection h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
}

.SupportSection svg {
  width: 30px;
  height: 30px;
  margin: 0 1rem 0 1rem;
}

@media (max-width: 768px) {
  .Intro img {
    width: 300px;
    opacity: 1;
  }

  .LogoContainer {
    align-items: center;
    justify-content: center;
  }

  .CustomRow {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .CustomRow .AccountCol:nth-child(1) {
    margin-bottom: 5rem;
  }

  .AccountCol {
    width: 100%;
    height: auto;
  }

  .CustomHero {
    /* overflow-y: hidden !important; */
    padding-top: 0 !important;
    height: initial;
  }

  .LogoContainer {
    padding: 2rem 0 1rem 0 !important;
  }

  .CustomContainer {
    padding: 3rem 2rem 4rem 2rem;
  }
}

@media (max-width: 991.98px) {
  .BigDaruma {
    display: none;
  }
}

@media (min-width: 992px) {
  .CustomContainer {
    max-width: 800px;
  }

  .AccountCol {
    width: 32%;
    /* height: 330px; */
  }
}

@media (min-width: 1200px) {
  .CustomContainer {
    max-width: 1000px;
  }

  .AccountCol {
    width: 32%;
    /* height: 330px; */
  }

  .AccountCol img {
    width: 300px;
  }

  .AccountCol h2 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }

  .AccountCol p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .AccountCol a {
    font-size: 0.8rem;
  }

  .ButtonContainer {
    margin-top: 1.5rem;
  }

  .BigDaruma {
    left: -100px;
    width: 200px;
  }
}

@media (min-width: 1500px) {
  .CustomContainer {
    max-width: 1250px;
  }

  .AccountCol {
    width: 32%;
    /* height: 400px; */
  }

  .AccountCol img {
    width: 380px;
  }

  .AccountCol h2 {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }

  .AccountCol p {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .AccountCol a {
    font-size: 1rem;
  }

  .ButtonContainer {
    margin-top: 1.8rem;
  }

  .BigDaruma {
    left: -100px;
    width: 200px;
  }
}

@media (min-width: 1700px) {
  .CustomContainer {
    max-width: 1500px;
  }

  .AccountCol h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .AccountCol p {
    /* padding-right: 5rem; */
    font-size: 1rem;
    line-height: 1.5rem;
    /* height: 120px; */
  }

  .AccountCol a {
    font-size: 1rem;
  }

  .ButtonContainer {
    margin-top: 2rem;
  }

  .AccountCol {
    width: 35%;
    /* height: 400px; */
  }

  .BigDaruma {
    left: -125px;
    width: 250px;
  }
}
